body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

footer {
	position: fixed;
	width: 100%;
	bottom: 0;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	z-index: 2;
	background: white;
}

footer > p {
	height: 16px;
}

@media (max-width: 580px) {
	.verificationList {
		transform-origin: left top;
		transform: scale(0.68);
	}
}

@media (max-height: 580px) {
	div.notificationText {
		bottom: 0px;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinner-container {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 99999;
}
.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid #f3f3f3; /* Light grey */
	border-top: 10px solid #383636; /* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}
